.pro-sidebar {
    background-color: #333;
    color: #fff;
  }
  
  .pro-menu-item {
    color: #fff;
  }
  
  .pro-menu-item:hover {
    background-color: #444;
  }
  
  .ck-editor__editable {
    min-height: 150px;
}
