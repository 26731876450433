@import url('./fonts.css');
*, ::after, ::before {
    box-sizing: border-box;
}
body, .front_page{ 
    font-family: 'Satoshi-Regular';
    font-size: 18px;
    font-weight: normal;
    color: #727272;
}
body p, .front_page p, body li{
    line-height: 1.5;
}
body p{
    margin: 0px 0px 20px;
}
h1,h2,h3,h4,h5,h6{
    font-family: 'Bremlin';
    line-height: 1.1;
    font-weight: 400;
    margin: 0px 0px 10px;
}
img{
    max-width: 100%;
}
.light, .light h1,.light h2,.light h3,.light h4, .light h5, .light h6{
    color:#000000;
}
.light p, .light span{
color:#727272;
}
.dark, .dark h1,.dark h2,.dark h3,.darkh h4, .dark h5, .dark h6, .dark p, .dark span{
    color:#ffffff;
}
ul {
    padding: 0px;
    margin: 0px;
}
.size40 {
    font-size: 40px;
    margin: 0px 0px 10px;
}
.size56 {
    font-size: 56px;
}
.size86 {
    font-size: 86px;
}
.size76 {
    font-size: 76px;
}
.size65 {
    font-size: 65px;
}
.size46 {
    font-size: 46px;
}
.p-130 {
    padding: 130px 0px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-60{
    margin-top: 60px;
}
.mt-0{
    margin-top: 0px !important;
}
.mb-0{
    margin-bottom: 0px !important;
}
 .pt-0{
    padding-top: 0px !important;
}
 .pb-0{
    padding-bottom: 0px !important;
}
.banner.dark h1{
    text-transform: uppercase;
}
body a, body button{
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
body a{
    text-decoration: none;
    color: inherit;
}
body a:hover{
    text-decoration: none;
    color: #65B5AF;
}
.cta_sq_green {
    background-color: #0F2D2F;
    width: 100%;
    border: 1px solid #0F2D2F;
    color: #ffffff;
    padding: 17px 20px;
    border-radius: 0px;
    font-size: 22px;
    line-height: 1.5;
    cursor: pointer;
}
.cta_sq_green:hover{
    background-color:  #65B5AF;
    border-color:  #65B5AF;
}

.cta {
    background-color: #0F2D2F;
    color: #ffffff;
    border: 0px;
    display: inline-block;
    text-align: center;
    padding: 17px 46px;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
}
.cta:hover {
    background-color: #65B5AF;
    color: #ffffff;
}
.cta.cta_light {
    background-color: #65B5AF;
    color: #fff;
}
.cta.cta_light:hover {
    background-color: #0F2D2F;
    color: #fff;
}
.cta.cta_square {
    border-radius: 0px;
}
.d-flex {
    display: flex;
}
.container_full {
    width: 100%;
    max-width: 1920px;
    padding-left: 60px;
    padding-right: 60px;
    margin-left: auto;
    margin-right: auto;
}

.container.container_small {
    width: 100%;
    max-width: 1295px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
}
.p-140 {
    padding: 140px 0px;
}
.text-center{
    text-align: center !important;
}
.inn_banner_section img.banner_image {
    width: 100%;
}
.banner_slider img.banner_image {
    width: 100%;
}
.login_header {
    text-align: center;
    margin: 0px 0px 30px;
}
.login_wrapper .inner_wrapper {
    border: 1px solid #EEEEEE;
    box-shadow: 0px 30px 40px 0px rgba(0, 0, 0, 0.07);
    margin: auto;
    padding: 90px 80px;
}
.form_control {
    border: 1px solid #EEEEEE;
    width: 100%;
    font-size: 18px;
    line-height: 1.5;
    font-family: 'Satoshi-Regular';
    color: #727272;
    padding: 20px 20px;
}
.form_group.field_with_icon {
    position: relative;
}
.field_with_icon .field_icon {
    position: absolute;
    left: 20px;
    top: 25px;
    line-height: 0;
}
.form_group.field_with_icon .form_control {
    padding-left: 50px;
}
.form_group {
    width: 100%;
    margin-bottom: 22px;
}

.form_control:focus {
    outline: none;
    border-color: #65B5AF;
}
.check_box_row {margin: 20px 0px;}

.form_check {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #727272;
    margin: 10px 0px;
}
.form_check input[type="checkbox"] {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    opacity: 0;
}

.form_check input[type="checkbox"] ~ .check_custom {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #65B5AF;
    color: #65B5AF;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 0px;
}

.form_check input[type="checkbox"]:checked ~ .check_custom {font-size: 18px;}

.flex_row.d-flex {
    align-items: center;
    justify-content: space-between;
}
.form_wrapper a {
    color: #65B5AF;
    text-decoration: none;
}
/* Add this to your CSS file */

.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container{
    padding-left: 15px;
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;
}
.password_group {
    position: relative;
}

.password_toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #999;
}

.password_toggle:hover {
    color: #666;
}
.page_link_text {
    margin: 20px 0px;
    text-align: center;
}
.page_link_text p a {
    margin-left: 10px;
}
.top_bar {
    background-color: #65B5AF;
    text-align: center;
    padding: 9px 10px;
}
.top_bar p {
    margin: 0px;
    color: #ffffff;
    font-size: 14px;
}
.top_bar p a {
    color: inherit;
    text-decoration: none;
}
.top_navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}
.site_search {
    width: 100%;
}
.site_search .search_wrap {
    width: 100%;
    max-width: 630px;
    width: 630px;
    position: relative;
    background-color: rgba(101, 181, 175, 0.03);
    border: 1px solid rgba(101, 181, 175, 0.11);
    border-radius: 50px;
    overflow: hidden;
}
.site_search input.search_input {
    border: 0px;
    font-size: 14px;
    padding: 18px 20px;
    height: 100%;
    width: 100%;
}
.site_search .search_wrap button.search_button {
    position: absolute;
    right: 8px;
    top: 0px;
    height: 100%;
    padding: 0px;
    width: 40px;
    background-color: transparent;
    border: 0px;
    font-size: 15px;
}
.site_search input.search_input:focus {
    outline: none;
    border: 0px;
    box-shadow: none;
}
.workshop_links .links_wrap {
    display: flex;
    gap: 70px;
    align-items: center;
}
a.icon_text_link {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000000;
    font-size: 16px;
    text-decoration: none;
    font-weight: 500;
}
.icon_links_col .icon_links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}
.middle_header {
    padding: 10px 0px;
}
.navigation_toggle {
    border: 0px;
    background-color: transparent;
    padding: 0px;
}
.navigation_toggle span.bar {
    width: 35px;
    display: block;
    height: 2px;
    background-color: #0F2D2F;
    margin: 6px 0px;
    border-radius: 10px;
}
.navigation_header {
    background-color: #0F2D2F;
}
.mobile_toggle_col {
    display: none;
}
.navigation_toggle {
    display: none;
}
.navigation_wrap {
    display: flex;
    justify-content: space-between;
}
.header_nav .navigation {
    display: flex;
    gap: 60px;
    margin: 0px;
}
ul{
    list-style: none;
}
.navigation .nav_link {
    display: inline-block;
    padding: 14px 0px;
    font-size: 16px;
    line-height: 21.6px;
    color: #ffffff;
    font-weight: 400;
    position: relative;
}
.navigation .nav_link:before {
    content: "";
    height: 2px;
    width: 0px;
    position: absolute;
    bottom: 0px;
    background-color: #65B5AF;
    right: 0px;
    left: 0px;
    margin: auto;
    transition: all 0.3s linear;
}
.navigation .nav_link:hover:before {
    width: 100%;
}
.navigation_button_col {
    display: flex;
    align-items: flex-end;
}

.navigation_button_col .blue_link {
    background-color: #65B5AF;
    color: #ffffff;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px 15px 0px 0px;
    padding: 8px 36px;
}
.icon_link span.value .value_text {
    background-color: #65B5AF;
    position: relative;
    display: flex;
    height: 33px;
    width: 32px;
    color: #ffffff;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    line-height: 12px;
    border: 6px solid #ffffff;
}
.icon_link {
    display: flex;
}
.icon_link span.value {
    display: inline-block;
    line-height: 0;
    margin-left: -11px;
    margin-top: -12px;
}
.banner {
    position: relative;
}
.banner_slider .slick-slide{
    overflow: hidden;
}
/* .banner_slider .slick-slide .banner_image {
    transition: all 0.4s ease-in-out;
    transform: scale(1.1);
}

.banner_slider .slick-slide.slick-current.slick-active .banner_image {
    transform: scale(1);
} */
.banner .banner_inner {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 100%;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    color: #ffffff;
}
.banner.dark h3 {
    color: #ffffff;
}
.banner.dark h1 {
    color: #ffffff;
}
.banner_content h3.baner_sub_head {
    font-size: 20px;
    line-height: 1.5;
    margin: 0px;
    text-transform: uppercase;
}
.banner p {
    font-size: 22px;
    text-transform: uppercase;
    width: 100%;
    max-width: 500px;
    margin: 0px auto 20px;
}
.banner_inner .banner_content {
    width: 100%;
    max-width: 940px;
    margin: auto;
}
.section_inner .green_subhead {
    text-transform: uppercase;
    font-size: 16px;
    color: #65B5AF;
    margin: 0px 0px 20px;
    font-weight: 700;
}
.inn_banner_section {
    position: relative;
    line-height: 0;
}
.inn_banner_section .innbanner_content_right {
    margin-left: auto;
    padding-bottom: 50px;
    padding-right: 10%;
}
.inn_banner_section .innbanner_content_right h2 {
    margin: 0px;
}
.inn_banner_section .inner {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.inn_banner_section .inner .container {
    width: 100%;
}
.banner_section {
    overflow-x: hidden;
    position: relative;
}
.banner_slider .slick-arrow.slick-prev {
    left: 0px;
}
.banner_slider .slick-arrow.slick-next {
    right: 0px;
}
.footer_links {
    border-top: 1px solid #65B5AF24;
    border-bottom: 1px solid #65B5AF24;
    position: relative;
}

.footer_links .chat_box {
    position: absolute;
    top: auto;
    right: 20px;
    bottom: 20px;
}

.footer-content .footer_contnt.mt_ft {
    border-left: 1px solid #65B5AF24;
    padding-left: 83px;
    height: 100%;
}

.footer-content .footer_grid {
    display: flex;
    justify-content: space-between;
    gap: 160px;
}

.footer-content .footer_border.mt_ft {
    max-width: 814px;
}

.footer-content .footer_contnt ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-content .footer_grid h5 {
    font-size: 26px;
    font-weight: 400;
    line-height: 32.5px;
    text-align: left;
    color: #000000;
}

.footer-content .footer_contnt ul a,
.footer_contnt ul li {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-decoration: none;
    color: #000;
    display: flex;
    align-items: center;
    gap: 10px;
}
.footer-content .footer_contnt ul a {
    position: relative;
    transition: all 0.3s ease-in-out;
}
.footer-content .footer_contnt ul a:before {
    content: "";
    height: 1px;
    width: 0px;
    position: absolute;
    bottom: -3px;
    background-color: #65b5af;
    left: 0px;
    transition: all 0.3s ease-in-out;
}

.footer-content .footer_contnt ul a:hover:before {
    width: 100%;
}
.footer_contnt ul li:not(:last-child) {
    margin-bottom: 20px;
}

.footer-content .link_list {
    display: flex;
    justify-content: space-between;
}

.footer-content .footer_contnt {
    flex-basis: 50%;
    padding-top: 80px;
}

.footer-content .footer_rt_txt p {
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #000000;
    padding-top: 30px;
    padding-bottom: 63px;
    margin: 0;
}

.footer-content .footer_link {
    max-width: 814px;
}

.footer-content .footer_contnt.mt_ft li {
    display: flex;
    padding-bottom: 45px;
    margin-bottom: 0px;
}

.footer-content .footer_contnt.mt_ft li strong {
    min-width: 80px;
}

.footer-content .footer_contnt.mt_ft li a,
.footer_contnt.mt_ft li {
    line-height: 24px;
    align-items: start;
    font-size: 16px;
    word-break: break-all;
}

.footer-content .footer_contnt.mt_ft .cards {
    display: flex;
    gap: 5px;
    align-items: center;
    padding-top: 20px;
}

.footer-content .footer_contnt.mt_ft .cards li,
.footer_contnt.mt_ft .cards li a {
    min-width: 50px;
    margin-bottom: 0 !important;
}

.footer-content .footer_row {
    display: flex;
}

.footer-content .footer_col:first-child {
    flex: 0 0 65%;
}

.footer-content .footer_col:last-child {
    flex: 0 0 35%;
}

.footer-content .footer_btm .cpy_rgt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0px;
}

.footer-content .footer_btm .cpy_rgt ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.footer-content .footer_btm .cpy_rgt ul li a,
.footer_btm .cpy_rgt p {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: right;
    color: #fff;
    margin: 0px;
}

.footer-content .footer_btm {
    background-color: #65B5AF;
}

.footer-content .footer_btm .cpy_rgt ul {
    display: flex;
    gap: 50px;
}

.marquee {
    position: relative;
    display: flex;
    width: 100%;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    justify-content: space-between;
    animation-name: marquee1;
}

@keyframes marquee1 {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}

.footer_marquee {
    overflow: hidden;
}
.footer_marquee .marq-img img {
    max-width: max-content;
}

/* category css */
.category-content {
    display: flex;
    gap: 30px 0px;
}

.category-content .categry-lft {
    flex: 0 0 30%;
    height: 100%;
    max-width: 338px;
}
.sidebar{
    padding: 25px 35px;
    border: 1px solid #65B5AF1C;
    border-radius: 15px;
    height: 100%;
}

.category-content h4 {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    color: #65B5AF;
    border-bottom: 1px solid #65B5AF;
    padding-bottom: 18px;
    margin-bottom: 18px;
}

.category-content .categories li a {
    font-size: 18px;
    font-weight: 400;
    line-height: 47px;
    text-align: left;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.category-content .categories li:hover>a{
    color: #65B5AF;
}
.categories li:hover .cat-block {
    display: block;
}

.category-content .categories .cat-block {
    padding: 20px;
    border: 1px solid #65B5AF1C;
    border-radius: 15px;
    box-shadow: 19px 24px 54px 0px #65B5AF21;
    display: none;
}

.category-content .categry-rgt {
    flex: 0 0 70%;
    padding-left: 28px;
}

  .prdt_list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 0px;
    margin-right: -15px;
    margin-left: -15px;
}
.prdt_list .product_box {
    width: 33.33%;
    padding: 0px 15px;
}
.category-content .categry-rgt h3 {
    text-align: left;
    margin-bottom: 33px;
}

.prdt_list .prdt {
    border: 1px solid #65B5AF1C;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    height: 100%;
    cursor: pointer;
}

 .prdt-info h6 {
    font-size: 20px;
    font-family: 'Satoshi-Medium';
    color: #000;
}
.category-content .categry-rgt  .prdt_list .product_box:hover .prdt-info h6{
color: #65B5AF;
}
.sidebar_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar_header h5 {
    margin: 0px;
    color: #65B5AF;
    font-size: 22px;
    font-weight: 400;
    width: 100%;
}

.sidebar_header i {
    min-width: 16px;
    color: #000000;
}
.sidebar_content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.sidebar_content.open {
    max-height: 500px; /* Adjust based on content height */
    transition: max-height 0.5s ease-in;
}

.sidebar_header {
    cursor: pointer;
}

.sidebar_header.open i {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.prdt-info h6 a {
    color: inherit;
}
.prdt_list .prdt-info .prodcut_category {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.6px;
    color: #727272;
    margin: 10px 0px;
    display: inline-block;
}

.prdt_list .prdt-info .price {
    font-size: 18px;
    font-weight: 700;
    line-height: 24.3px;
    color: #65B5AF;
}

  .prdt_list .prdt-info {
    margin-top: auto;
    height: auto;
}

 .prdt_list .prdt-img {
    height: 100%;
    padding-top: 100%;
    position: relative;
    width: 100%;
}

 .prdt_list .prdt-img img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.prdt_list .prdt:hover {
    box-shadow: 19px 24px 54px 0px #65B5AF21;
}

.category-content .categry-rgt   .show_btn {
    text-align: center;
    margin-top: 60px;
}
.closet_module img.abs_right {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    max-width: 15%;
}
.closet_module {
    position: relative;
}
.insta_flex {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.insta_flex .insta_post {
    flex: 1;
    padding: 0px 10px;
}

.insta_head {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 30px;
    color: #65B5AF;
    gap: 11px;
    align-items: center;
}

.insta_head p {
    color: #65B5AF;
    font-size: 30px;
    margin: 0px;
}

.insta_head .insta_icon {
    color: #000000;
    font-size: 32px;
    line-height: 0;
}

.instagram_section {
    padding: 85px 0px;
}
.ins_post_card {
    line-height: 0px;
    position: relative;
    padding-top: 100%;
}
.ins_post_card img {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.newsleter_section {
    padding: 180px 0px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.newsletter_form {
    width: 100%;
    max-width: 654px;
    margin-left: auto;
    margin-right: auto;

}
.newsletter_form .news_wrap{
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-width: 1px 0px 1px 0px;
    display: flex;
}
.news_wrap .newsletter_button {
    background-color: transparent;
    border: 0px;
    box-shadow: none;
    padding: 0px;
    width: 45px;
    min-width: 45px;
}
.news_wrap .newsletter_input {
    width: calc(100% - 45px);
    background-color: transparent;
    border: 0px;
    color: #ffffff;
    font-size: 16px;
    padding: 19px 0px;
}
.news_wrap .newsletter_input:focus {
    outline: none;
    box-shadow: none;
}
.newsletter_input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #ffffff;
  }
  .newsletter_input::-moz-placeholder { /* Firefox 19+ */
    color: #ffffff;
  }
  .newsletter_input:-ms-input-placeholder { /* IE 10+ */
    color: #ffffff;
  }
  .newsletter_input:-moz-placeholder { /* Firefox 18- */
    color: #ffffff;
  }
  .full_grid {
    display: flex;
    flex-wrap: wrap;
}

.new_arrival .full_grid_col {
    width: 50%;
}

.full_grid_col.media_col {
    background-repeat: no-repeat;
    position: relative;
    line-height: 0px;
}
.full_grid_col.text_side {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
}
.full_grid_col.text_side .grid_inner {
    width: 100%;
    max-width: 588px;
    margin: auto;
}
.cta.cta_trans {
    background-color: transparent;
    border: 1px solid #ffffff;
}
.cta.cta_trans:hover {
    background-color: #65b5af;
    border-color: #65b5af;
}
.product_thumb {
    background-color: rgba(101, 181, 175, 0.08);
    border: 1px solid rgba(101, 181, 175, 0.14);
    border-radius: 60px;
    padding: 100px;
    width: 100%;
    max-width: 544px;
    margin: 0px auto 25px;
}

.grid_inner.priduc_inner p {
    color: #727272;
    font-size: 16px;
    width: 100%;
    max-width: 370px;
    margin: 0px auto 20px;
}
.full_grid_col.media_col img.main_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.section_head.d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 40px;
}
.category_row {
    display: flex;
    margin-left: -10px;
}
.category_col {
    padding: 0px 10px;
}
.category_box h4 {
    font-size: 26px;
    font-family: 'Satoshi-Regular';
    font-weight: 700;
    color: #000000;
}
.closet_module .section_inner {
    width: 100%;
    max-width: 84%;
    margin: auto;
}
.category_box h4 a {
    color: inherit;
}
.category_title {
    margin-top: 18px;
}
.cate_thumb {
    overflow: hidden;
    display: inline-block;
    width: 100%;
}
.cate_thumb .category_thumb_image {
    width: 100%;
    transition: all 0.4s ease-in-out;
    transform: scale(1);
}
.category_slider {
    margin-left: -10px;
}
.category_box:hover .cate_thumb .category_thumb_image {transform: scale(1.01);}
.category_section {
    overflow: hidden;
}
.category_slider {
    position: relative;
}

.category_slider:before {
    content: "";
    height: 100%;
    width: 1000%;
    background-color: white;
    position: absolute;
    right: 100%;
    z-index: 1;
}
.category_slider .slick-list {
    overflow: visible;
    padding-right: 10% !important;
}
.cate_marque_slider {
    display: flex;
    gap: 20px;
}
.cate_marque_slider .marque_slide {
    display: flex;
    align-items: center;
}
.cate_marque_slider .marque_slide .star_icon {
    margin: 0px 20px;
    min-width: 30px;
}
.cate_marque_slider .slick-track {
    display: flex;
    flex-wrap: nowrap;
}
.cate_marque_slider p {
    font-weight: 500;
    font-size: 21px;
    color: #000000;
    white-space: nowrap;
    margin: 0px 0px 0px;
}
.category_marquee_div {
    background-color: rgba(101, 181, 175, 0.09);
    border: 1px solid rgba(101, 181, 175, 0.23);
    border-width: 1px 0px 1px 0px;
    padding: 26px 0px;
    height: 87px;
}
.aboutext_col p.size32 {
    font-size: 32px;
    color: #000000;
}
.aboutext_col p {
    font-size: 20px;
}
.about_grid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.about_grid .aboutext_col {
    width: 60%;
    padding-right: 150px;
}
.about_grid .about_media_col {
    width: 40%;
}

.about_section_head {
    width: 100%;
    max-width: 100%;
    margin: auto;
    position: relative;
    padding: 0px 232px;
}
.about_section_head img.green_logoe {
    position: absolute;
    right: 0px;
    bottom: 0px;
    max-width: 120px;
}
.about_thumb img {
    width: 100%;
}
.size45 {
    font-size: 45px;
}

.review_grid {
    display: flex;
    margin-left: -10px;
}
.review_box {
    border: 1px solid rgba(163, 126, 44, 0.11);
    border-radius: 25px;
    overflow: hidden;
    padding: 45px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.review_col {
    padding: 0px 10px;
    flex: 1;
}

.review_head {
    width: 100%;
    margin-bottom: 30px;
}

.review_footer {
    margin-top: auto;
    border-top: 1px solid rgb(163 126 44 / 17%);
    padding-top: 20px;
}
.review_box .rating {
    display: flex;
    align-items: center;
    color: #000000;
    margin-bottom: 14px;
}
.review_box .rating span {
    color: #000000;
    font-weight: 500;
}
.review_footer .review_product {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.review_footer .review_product h4 {
    font-size: 15px;
    margin: 0px;
}

.progress-bar-container {
    width: 100%;
    height: 5px;
    background-color: rgba(101, 181, 175, 0.07);
    margin-top: 15px;
    position: relative;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #65B5AF;
    transition: width 0.1s linear;
  }
  
  .slide-counter {
    font-size: 20px;
    color: #000000;
}
  .slide-counter span.active_ciunter {
    font-size: 29px;
}
  .progress-bar-container {
    overflow: hidden;
}
.reviews_slider button.slick-arrow {
    border: 1px solid #0F2D2F;
    background-color: #0F2D2F;
    opacity: 1;
    height: 36px;
    width: 58px;
    border-radius: 50px;
    right: 0px;
    bottom: 0px;
    top: auto;
    transform: translateY(0px);
}
.reviews_slider .slick-arrow.slick-prev {
    right: 70px;
    left: auto;
}
.reviews_slider button.slick-arrow::before {
    font-family: 'FontAwesome';
    color: #ffffff;
    opacity: 1;
}
.reviews_slider {
    padding-bottom: 60px;
}

.reviews_slider button.slick-arrow.slick-disabled{
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: transparent;
}
.reviews_slider button.slick-arrow.slick-disabled::before {
    color: rgba(0, 0, 0, 0.1);
    opacity: 1;
}
.banner_slider .slick-prev:before, .banner_slider .slick-next:before {
    font-family: 'FontAwesome';
    position: static;
}
.banner_slider .slick-prev:before {content: "\f053";}
.banner_slider .slick-next:before {
    content: "\f054";
}

.banner_slider .slick-prev, .banner_slider .slick-next {
    padding: 10px;
    height: 175px;
    width: 175px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    z-index: 1;
}
.banner_slider .slick-prev {
    transform: translateX(-50%) translateY(-50%);
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    padding-right: 50px;
}
.banner_slider .slick-next {
    transform: translateX(50%) translateY(-50%);
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    padding-left: 50px !important;
}

.newsleter_inner {
    width: 100%;
    max-width: 88%;
    margin: auto;
}
.productssection_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin: 0px 0px 40px;
}

.sort_by_filter {
    display: flex;
    gap: 15px;
    align-items: center;
}

.sort_by_filter select {
    padding: 20px;
    border: 1px solid #373737;
    padding: 12px 30px;
    border-radius: 50px;
}

.sort_by_filter select:focus {
    border-color: #65B5AF;
    outline: none;
}
.sidebar_box:not(:last-child) {
    border-bottom: 1px solid #65b5af47;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.filter_check_wrapper label.form_check {
    font-size: 18px;
    color: #000000;
    margin: 10px 0px;
}
.sidebar_box .form_check input[type="checkbox"] ~ .check_custom {
    height: 19px;
    width: 18px;
    border-color: #373737;
}
/* FilterSidebar.css */
.price_filter .slider_wrapper {
    padding: 0px 13px;
}

.price_inputs {
    display: flex;
    gap:14px;
    margin: 20px 0px 10px;
}
body input:focus{
    outline:none;
}
.price_inputs input {
    width: 74px;
    border-radius: 5px;
    border: 1px solid #65B5AF;
    font-size: 14px;
    color: #ACACAC;
    padding: 11px 10px;
    text-align: center;
    line-height: 1.5;
}
.apply_button {
    background-color: #0F2D2F;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    width: 75px;
}

.price_filter .rc-slider-handle {
    height: 25px;
    width: 25px;
    background-color: #65B5AF;
    opacity: 1 !important;
    border: 0px !important;
    margin-top: -11px;
}
.price_filter .rc-slider {
    padding: 20px 0px;
}
.price_filter .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #65B5AF;
    box-shadow: none;
  }
  /*---- breadcrumb css start ------*/
  .front_page .breadcrumb {
    padding: 10px 0px;
}
ul.breadcrumb_list {
    display: flex;
    gap: 10px;
}
ul.breadcrumb_list li {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
ul.breadcrumb_list a.breadcrumb_link {
    color: #000000;
}
ul.breadcrumb_list a.breadcrumb_link:hover {
    color: #65b5af;
}
li.breadcrumb_item.active {
    font-weight: 700;
}
.product_detail_row {
    display: flex;
    flex-wrap: wrap;
}
.product_media_detail,.product_detail_col {
    width: 50%;
}
.product_media_slider {
    display: flex;
    flex-direction: row-reverse;
}
.product_nav_slider {
    width: 110px;
    min-width: 110px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
}
.product_for_slider {
    width: calc(100% - 140px);
}
.product_media_detail .product_image {
    border: 1px solid rgba(101, 181, 175, 0.2);
    border-radius: 25px;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
}
.product_media_detail .product_image .product_main_thumb {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.product_nav_slider .product_image {
    border-radius: 5px;
}
.product_nav_slider .product_nav_slide:not(:last-child) {
    margin-bottom: 16px;
}
.product_nav_slider .slick-list {
    padding: 10px 0px;
}
.login_wrapper .container {
    width: 80%;
    max-width: 1295px;
}
.product_detail_col {
    padding-left: 45px;
}
.Product_detail_component .category_text {
    font-weight: 500;
    font-family: 'Satoshi-Bold';
    margin: 0px 0px 10px;
}

h2.product_title {
    font-size: 36px;
    color: #000000;
    margin: 00px 0px 5px;
}

.Product_detail_component p {
    width: 100%;
    max-width: 620px;
}

.product_review {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: #000000;
    margin: 0px 0px 12px;
}
.product_price {
    font-family: 'Satoshi-Bold';
    font-size: 32px;
    color: #000000;
    line-height: 1.5;
}
.quantity_box {
    border: 1px solid #65B5AF;
    border-radius: 150px;
    overflow: hidden;
    display: flex;
    width: 100%;
    max-width: 142px;
    justify-content: space-between;
    height: 100%;
}

.quantity_box input.quantity_input {
    border: 0px;
    padding: 0px;
    width: 40px;
    text-align: center;
    font-size: 20px;
}

.quantity_box span.quantity_toggle {
    padding: 5px;
    font-size: 15px;
    min-width: 36px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.quantity_cart_wrap {
    display: flex;
    gap: 20px;
}
.fav_button {
    width: 58px;
    height: 58px;
    border: 2px solid #0F2D2F;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;
    cursor: pointer;
}
.fav_button img {
    transition: all 0.3s linear;
}
.fav_button:hover {
    background-color: #65b5af;
    border-color: #65b5af;
}
.fav_button:hover img {
    filter: invert(1);
}
.share_product label {
    font-family: 'Satoshi-Medium';
    color: #000000;
}
.share_product {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0px 20px;
}
.share_icons {
    display: flex;
    align-items: center;
    gap: 14px;
}
.share_icons a {
    font-size: 18px;
    color: #65b5af;
}
.quantity_cart_wrapper {
    margin: 20px 0px;
}
p.quantity_label {
    color: #000000;
}
p.quantity_label {
    color: #000000;
}
.slaeinfo_text {
    display: flex;
    color: #000000;
    gap: 10px;
    margin: 0px 0px 8px;
}
.slaeinfo_text .sale_info_icon {width: 26px;height: 26px;object-fit: contain;}
.slaeinfo_text p {
    width: calc(100% - 26px);
    margin: 0px;
}
.product_nav_slider .slick-slide {
    opacity: 0.4;
}
.product_nav_slider .slick-slide.slick-active.slick-current {
    opacity: 1;
}
ul.tab_links {
    display: flex;
}
.tab_links li {
    padding: 17px 37px;
    font-size: 18px;
    color: #000000;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    cursor: pointer;
}
.description_tab_wrap .tab_content {
    padding: 30px 0px;
    border-top: 1px solid #65B5B2;
}
.tab_links li.active {
    border-color: #65B5B2;
    border-bottom-color: #ffffff;
    margin-bottom: -1.8px;
    font-weight: 600;
    color: #0F2D2F;
}
.rel_product_slider .slick-track {
    display: flex;
}

.rel_product_slider .slick-slide {
    height: auto !important;
}
.rel_product_slider .slick-slide > div {
    height: 100%;
}

.rel_product_slider .slick-slide .product_box {
    height: 100%;
}
.rel_product_slider button.slick-arrow {
    border: 1px solid #0F2D2F;
    background-color: #0F2D2F;
    opacity: 1;
    height: 36px;
    width: 58px;
    border-radius: 50px;
    right: 0px;
    bottom: auto;
    top: -70px;
    transform: translateY(0px);
}
.rel_product_slider .slick-arrow.slick-prev {
    right: 70px;
    left: auto;
}
.rel_product_slider button.slick-arrow::before {
    font-family: 'FontAwesome';
    color: #ffffff;
    opacity: 1;
}

.rel_product_slider button.slick-arrow.slick-disabled{
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: transparent;
}
.rel_product_slider button.slick-arrow.slick-disabled::before {
    color: rgba(0, 0, 0, 0.1);
    opacity: 1;
}
.quantity_cart_wrap .cta {
    height: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    width: 400px;
    justify-content: center;
}
.slick-slider.prdt_list .prdt:hover {
    box-shadow: none;
}
.cart_content .cart-summary h6 {
    font-size: 22px;
    font-weight: 400;
    line-height: 27.5px;
    margin-bottom: 16px;
}

.coupon_apply {
    display: flex;
    gap: 15px;
}

.cta.light {
    background: #fff;
    color: #0F2D2F;
    padding: 13px 36px;
}

.cart_content  .cart-summary input {
    width: 100%;
    padding: 15px 22px;
    border: 1px solid #FFFFFF4D;
    border-radius: 200px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.9px;
    text-align: left;
    color: #fff;
    background: transparent;
}

.cart_content  .cart-summary input::placeholder{
    color: #fff;
}
.cart-container {
    display: flex;
    justify-content: space-between;
    gap: 65px;
}

.cart_content  .cart-items {
    flex-basis: 65%;
}

.cart_content table {
    width: 100%;
    border-collapse: collapse;
}

.cart_content h4 {
    font-size: 42px;
    font-weight: 400;
    line-height: 52.5px;
    margin: 0;
}

.cart_content  .cart_order {
    margin: 30px 0px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.3px;
}

.cart_content  table th {
    padding:15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.6px;
    color: #727272;
}

.cart_content  table td {
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    padding: 30px 20px;
    text-align: center;
}

.cart_content table th:first-child, .cart_content table td:first-child {
    padding-left: 0;
    text-align: left;
}

.cart_content  table th:last-child,.cart_content  table td:last-child {
    text-align: right;
}

.cart_content table tbody tr{
    border-top: 1px solid #65B5AF4D
}

.cart_content .product-info {
    display: flex;
    align-items: center;
    gap: 25px;
}

.cart_content .product-info .prdt-img {
    border: 1px solid #65B5AF33;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart_content  .qty-control {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.cart_content  .qty-control input {
    width: 40px;
    text-align: center;
    border: 1px solid #ddd;
    margin: 0 5px;
}

.cart_content .add-more {
    width: 100%;
    text-align: center;
}
.cart_content .cart-summary {
    flex-basis: 35%;
}
.cart_content .cart-summary .cart_summery_box {
    background-color: #65B5AF;
    border-radius: 25px;
    padding: 35px 30px;
    color: #fff;
}

.cart_content .cart-summary h3 {
    font-size: 20px;
    margin-bottom: 20px;
}

.cart_content .cart-summary ul {
    list-style: none;
    padding: 0;
    margin:0;
}

.cart_content .total-amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart_content table .delete-btn {
    border: 1px solid #D9D9D9;
    height: 34px;
    width: 34px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: #000000;
    font-size: 14px;
}

.cart_content .qty-control button,.cart_content .qty-control input {
    background: transparent;
    border: 0;
}

.cart_content .qty-control {
    border: 1px solid #65B5AF;
    border-radius: 150px;
    padding: 15px;
}

.cart_content .qty-control input {
    font-size: 22px;
    font-weight: 700;
    line-height: 29.7px;
}

.cart_content .qty-control a i {
    color: #727272;
    font-size: 20px;
}

.cart_content  .amnt_detail {
    margin: 40px 0px 20px;
    padding: 30px 0px;
    border-top: 1px solid #FFFFFF33;
    border-bottom: 1px solid #FFFFFF33;
}

.cart_content  .amnt_detail ul li,.cart_content  .amnt_detail .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.02em;
}

.cart_content  .amnt_detail .title {
    font-size: 18px;
}

.cart_content  .ad_info {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
}

.cart_content .total-amount p {
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
}

.cart_content .total-amount p:last-child {
    font-size: 22px;
    font-weight: 400;
    line-height: 27.5px;
}
.cart_table .quantity_box {
    min-height: 58px;
}
.cart_table .product-info p {
    font-family: 'Satoshi-Medium';
    color: #000000;
}
td[data-title="Price"] {
    font-family: 'Satoshi-Bold';
}
.faq_section .faq-heading h2 {
    text-align: center;
    color: #000;
}

.faq_section .tab-button {
    padding: 10px 20px;
    border: none;
    background-color: #f1f1f1;
    cursor: pointer;
}

.faq_section .tab-button.active {
    background-color: #007bff;
    color: white;
}

.faq_section .tab-content {
    margin-top: 20px;
}

.faq_section .tab-panel.hidden {
    display: none;
}

.faq_section .tab-panel.active {
    display: block;
}

.faq_section .accordion-item {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.faq_section .accordion-title {
    padding: 10px;
    background-color: #f1f1f1;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
}


.faq_section .accordion-icon {
    transition: transform 0.3s ease;
}

.faq_section .accordion-icon.open {
    transform: rotate(180deg);
}

.faq_section .accordion-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.faq_section .accordion-content p {
    margin: 10px 0;
    padding: 10px;
    background-color: #fff;
}

.faq_section .accordion-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
}

.faq_section .accordion-content.visible {
    opacity: 1;
}

.faq_section .accordion-content.hidden {
    opacity: 0;
}

.faq_section .tabs {
    border: 1px solid #000000;
    max-width: 975px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-radius: 200px;
}

.faq_section button.tab-button {
    background-color: transparent;
    border-radius: 200px;
    padding: 13px 38px;
    font-family: 'Satoshi-Regular';
    font-size: 18px;
    font-weight: 700;
    line-height: 24.3px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.faq_section button.tab-button.active {
    background: #0F2D2F;
}

.faq_section .tab-wrapper {
    padding-top: 30px;
}

.faq_section .tab-content {
    margin-top: 60px;
}

.faq_section button.accordion-title {
    border: 0;
    outline: none;
    font-family: 'Satoshi-Regular';
    font-size: 24px;
    font-weight: 700;
    line-height: 32.4px;
    text-align: left;
    padding: 24px 30px;
    overflow: visible;
    background-color: transparent;
    border-radius: 10px !important;
    letter-spacing: 0.6px;
}

.faq_section .accordion-item {
    border: 1px solid #E1E1E1 !important;
    background-color: transparent !important;
    margin-bottom: 30px;
    border-radius: 10px !important;
}

.faq_section .accordion-item p {
    margin-top: 0;
    padding: 30px;
    padding-top: 0;
    border-radius: 15px;
    background-color: transparent;
    font-family: 'Satoshi-Regular';
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #727272;
}

.faq_section .accordion-item:last-child {
    margin: 0;
}

.faq-section .faq-wrapper {
    max-width: 1246px;
    margin: auto;
}

.faq_section .accordion-content {
    max-width: 1100px;
    width: 100%;
}

span.tab-icon.open i {
    rotate: 180deg;
    transition: transform 0.3s ease;
}

.certificate_form button.tab-button {
    font-size: 16px !important;
    padding: 13px 32px !important;
}

.certificates_section .tab-wrapper {
    padding: 0;
}

.certificates_section label.form_check {
    gap: 18px;
}

.certificates_section textarea {
    border: 1px solid #EEEEEE;
    width: 100%;
    font-size: 18px;
    line-height: 1.5;
    font-family: 'Satoshi-Regular';
    color: #727272;
    padding: 20px 20px;
    border-radius: 5px;
}

.certificates_section textarea:focus {
    outline: none;
    border-color: #65B5AF;
}

.certificates_section form {
    box-shadow: 0px 15px 36px 0px #0000000F;
    padding: 50px;
    max-width: 1000px;
    margin: auto;
    border-radius: 25px;
}

.certificates_section .form_control {
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
}

.tab-content .d-flex {
    gap: 20px;
}

.custom-radio .form_group {
    align-items: center;
    display: flex;
    width: 100%;
    gap: 15px;
}

.custom-radio input {
    height: 20px;
    width: 20px;
}

.custom-radio label {
    font-family: 'Satoshi-Regular';
    font-size: 20px;
    font-weight: 500;
    line-height: 40px;
    color: #727272;
}

.form_group.radio-btn p {
    font-family: 'Satoshi-Regular';
    font-size: 22px;
    font-weight: 600;
    line-height: 29.7px;
    color: #0F2D2F;
}

.certificates_section .tab-content h3 {
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}

.certificates_section form .form_btnn {
    padding-top: 60px;
    display: flex;
    gap: 30px;
}

.certificates_section form .form_btnn button {
    background: #0F2D2F;
    color: #fff;
    text-transform: uppercase;
    outline: none;
    border: 2px solid #0F2D2F;
    padding: 17px 30px;
    border-radius: 200px;
    font-family: 'Satoshi-Regular';
    font-size: 18px;
    font-weight: 700;
    line-height: 24.3px;
    text-align: left;
}

.certificates_section form .form_btnn button:hover {
    background-color: #fff;
    color: #0F2D2F;
}

.certificates_section form button.preview_btn {
    border: 2px solid #D9D9D9;
    background-color: #fff;
    color: #D9D9D9;
}

.certificates_section form button.preview_btn:hover {
    background: #0F2D2F;
    color: #fff;
}
.hover_box_row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    gap: 30px 0px;
}
.hover_box_col {
    width: 33.33%;
    padding: 0px 15px;
}
.icon_box {
    border: 1px solid #EEEEEE;
    padding: 35px;
    transition: all 0.3s ease-in-out;
    box-shadow: none;
}
.icon_box:hover {
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}
.icon_boix_inner {
    text-align: center;
}
.icon_box .icon {
    height: 74px;
    width: 74px;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0px auto 20px;
    transition: all 0.3s ease-in-out;
}

.icon_box h3 {
    margin: 0px 0px 10px;
    line-height: 1.5;
    font-family: 'Satoshi-Bold';
    transition: all 0.3s ease-in-out;
}
.icon_box .icon img {
    transition: all 0.3s ease-in-out;
}
.icon_box:hover .icon {
    background-color: #0F2D2F;
    border-color: #0F2D2F;
}
.icon_box:hover h3 {color: #0F2D2F !important;}
.icon_box:hover .icon img {
    filter: invert(1);
}
.form_row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}
.form_row .form_group {
    padding: 0px 10px;
}

.contact_form .form_control {
    border-radius: 5px;
}
.contact_row {
    display: flex;
    flex-wrap: wrap;
}
.contact_row .contact_col {
    width: 50%;
}
.contact_col.address_col {
    padding-right: 40px;
}
.address_col .section_head {
    width: 100%;
    max-width: 460px;
    margin-bottom: 40px;
}
.address_info {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.address_info .address_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 312px;
}
.address_box 
 .addreess_icon {
    min-width: 50px;
    width: 50px;
    margin-top: 2px;
}
.address_box 
 .address_text {
    width: calc(100% - 50px);
    padding-left: 10px;
}
.address_text  p {
    margin: 0px 0px 0px;
}
.address_text p a {
    color: inherit;
}
.address_text p b {
    color: #000000;
    font-family: 'Satoshi-Bold';
}

.servicing_heading {
    text-align: center;
}

.servicing_heading span {
    font-size: 16px;
    font-weight: 700;
    line-height: 21.6px;
    letter-spacing: 0.06em;
    color: #65B5AF;
    text-transform: uppercase;
    margin-bottom: 23px;
    display: block;
}

.servicing_heading {
    max-width: 1093px;
    margin: auto;
}

.servicing_heading a.cta {
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 60px;
    display: inline-block;
}

.servicing_table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0;
    background-color: transparent;
}

/* .servicing_table th,
.servicing_table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
} */

.servicing_table th {
    background-color: transparent;
    font-weight: bold;
    padding: 20px 30px;
    border-bottom: 1px solid #D9D9D9;
    border-right: 1px solid #65B5AF;
}

.servicing_table th:last-child {
    border-right: 0px;

}

.servicing_table tbody th:last-child {
    border: 0px !important;
}

.servicing_table tbody td {
    border-bottom: 1px solid #D9D9D9;
    border-right: 1px solid #65B5AF;
}

.servicing_table tbody td:last-child {
    border-right: 0;
}

.servicing_table td img {
    display: block;
    margin: auto;
}

.servicing_table {
    margin: 90px 0px;
    border: 1px solid #D9D9D9;
    border-radius: 25px;
}

.servicing_table h3 {
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    color: #65B5AF;
    margin-bottom: 20px;
}

.servicing_table h4 {
    color: #65B5AF;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    line-height: 37.5px;
}

.servicing_table th.list-table p {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    margin-bottom: 5px;
}

.servicing_table th.list-table span {
    text-align: center;
    display: block;
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
}

.servicing_table th.list-table b {
    font-weight: 900;
    font-style: italic;
    font-family: 'Satoshi-Regular';
}

.servicing_table tbody th {
    font-family: 'Satoshi-Regular';
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    text-align: left;
}

.servicing_table tr.last_tr th {
    border-bottom: 0;
}

.servicing_table tr.last_tr td {
    border-bottom: 0;
}

.workshop_content {
    padding-bottom: 50px;
}

.workshop_wrapper {
    padding-top: 30px;
    max-width: 1348px;
}
.servicing_table th:nth-child(even) {
    background: #65B5AF14;
}
.servicing_table tbody td:nth-child(even) {
    background: #65B5AF14;

}
.privacy_data p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #727272;
    margin-bottom: 20px;
}
.privacy_data a {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #727272;
    margin-bottom: 20px;
}
.privacy_data h3 {
    margin-bottom: 15px;
}
.hidden{
    display: none;
}
.store_wrapper_outer {
    margin-top: 40px;
    box-shadow: 0px 15px 36px 0px rgba(0, 0, 0, 0.06);
    border-radius: 25px;
    overflow: hidden;
    padding: 45px;
}

.step_inner .section_head {
    width: 80%;
    max-width: 1093px;
    margin-left: auto;
    margin-right: auto;
}

.store_row {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 0px;
    margin-left: -15px;
    margin-right: -15px;
}

.store_row .store_col {
    width: 33.33%;
    padding: 0px 15px;
}

.store_thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s linear;
    transform: scale(1);
}

.store_thumb {
    line-height: 0;
    border-radius: 25px 25px 0px 0px;
    overflow: hidden;
    border: 1px solid transparent;
}

.store_card_inner {
    border: 1px solid #E3E9EA;
    border-radius: 0px 0px 25px 25px;
    padding: 0px 25px 25px;
    border-width: 0px 1px 1px 1px;
}

.store_card_inner .store_info {
    margin: 0px;
    font-size: 16px;
}

.store_card_inner .dis_tag {
    background-color: #65B5AF;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    padding: 7px 17px;
    display: inline-block;
    border-radius: 50px;
    transform: translateY(-50%);
}

.store_card_inner .store_title {
    font-family: 'Satoshi-Medium';
    font-size: 18px;
    margin: 0px 0px 10px;
}
.store_box:hover .store_thumb img {
    transform: scale(1.01);
}
.Module_head {
    margin-bottom: 20px;
}
.custom_progress {
    background-color: rgba(101, 181, 175, 0.11);
    height: 16px;
    border-radius: 50px;
    overflow: hidden;
}

.custom_progress .progress_thumb {
    height: 100%;
    background-color: #65B5AF;
    border-radius: 50px;
}

.step_progress p {
    font-family: 'Satoshi-Medium';
    font-size: 18px;
    margin: 0px 0px 10px !important;
    color: #000000;
}

.step_progress {
    text-align: left;
    margin: 0px 0px 50px;
}
.servicetoggle_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000000;
    cursor: pointer;
}
.servicetoggle_head p {
    color: #000000;
    font-family: 'Satoshi-Bold';
    font-size: 28px;
    margin: 0px;
}
.servicetoggle_head .toggle_icon {
    color: #000000;
    font-size: 28px;
}
.service_content_wrapp {
    border: 1px solid #E3E9EA;
    padding: 30px;
    border-radius: 10px;
    margin-top: 30px;
}
.service_select_wrapper:not(:last-child) {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #E3E9EA;
}
.service_radio_row {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    gap: 30px 0px;
    flex-wrap: wrap;
}

.service_radio_row .service_radio_col {
    width: 33.33%;
    padding: 0px 15px;
}
label.checkcontainer {
    position: relative;
    display: flex;
}
label.checkcontainer input {
    position: absolute;
    width: 100%;
    opacity: 0;
    z-index: 1;
    height: 100%;
}

.checkcontainer .radiobtn {
    display: block;
    height: 26px;
    width: 26px;
    min-width: 26px;
    border: 1px solid #65B5AF;
    border-radius: 50%;
    margin-top: 4px;
}

.checkcontainer input:checked ~ .radiobtn {
    border-width: 8px;
}

.radio_info {
    padding-left: 13px;
}

.radio_info p {
    margin: 0px;
    font-size: 20px;
    color: #000000;
}

.radio_info .service_price {
    font-size: 24px;
    font-family: 'Satoshi-Bold';
}
.secondry_service_wrap p {
    color: #000000;
    font-family: 'Satoshi-Medium';
    font-size: 22px;
}
.secondry_service_wrap .form_control {
    border-radius: 10px;
}
.service_select_content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out, padding 0.4s ease-out;
    padding: 0 15px; /* initial padding */
}

.service_select_content.open {
    max-height: 500px; /* Adjust based on the content height */
}

.toggle_icon {
    display: inline-block;
    transition: transform 0.3s ease;
}

.toggle_icon.open {
    transform: rotate(180deg);
}
.button_flex {
    display: flex;
    justify-content: space-between;
    gap: 40px;
}
.button_flex .cta {
    flex: 1;
}
.cta.step_back {
    background-color: rgba(15, 45, 47, 0.1);
    color: #000000;
}
.store_service_step .step_inner {
    width: 100%;
    max-width: 1200px;
    margin: auto;
}
.calender_step .step_inner {
    width: 100%;
    max-width: 1000px;
    margin: auto;
}
.calender_step .store_wrapper_outer {
    padding: 0px;
}

/* detail form step */

.detailform_step .step_inner{
    max-width: 900px;
    margin: auto;
}
.detail-form-box {
    background: #FFFFFF;
    box-shadow: 0px 15px 36px 0px #0000000F;
    padding: 31px 50px;
    border-radius: 25px;   
}
.detail-form-box .detail-form_row.form_row {
    flex-wrap: nowrap;
}
.form_group select.form_control {
    background: transparent;
}


/* bike detail page start here */

.bikedetail-form-btn a {
    background: #65B5AF;
    width: 100%;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.3px;
    text-align: left;
    color: #fff;
    padding: 19px;
    text-align: center;
    border-radius: 5px;
}
.form_control {
    border-radius: 5px;
}
.bikedetail-form-btn a.addbike-btn {
    margin-bottom: 22px;
}
.bikedetail-form-btn a.savebike-btn {
    background: #0F2D2F;
}
.bikedetailform_step .step_inner {
    max-width: 900px;
    margin: auto;
}
.bikedetail-form-box {
    background: #FFFFFF;
    box-shadow: 0px 15px 36px 0px #0000000F;
    padding: 31px 50px;
    border-radius: 25px;
}
.bikedetail-form-btn a:hover{
    background: #65B5AF;  
    color: #fff;
}
.bikedetail-form-btn a.addbike-btn:hover{
    background: #0F2D2F; 
    color: #fff; 
}





/* summary total bill  */
.form_control {
    border-radius: 5px;
}
.summary-bill-box {
    background: #FFFFFF;
    box-shadow: 0px 15px 36px 0px #0000000F;
    padding: 31px 50px;
    border-radius: 25px;
}
.summary_step .step_inner {
    max-width: 900px;
    margin: auto;
}
.summary-bill-box table {
    /* border-spacing: 10px; */
    border-collapse: collapse;
    width: 100%;
}
.summary-bill-box table tr td {
    /* margin-bottom: 20px; */
    padding: 7px 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    text-align: left;
    color: #000;

}
.summary-bill-box table tr.total-price {
    border-top: 1px solid #000000;
    padding-top: 20px;
    /* display: table; */
    /* width: 100%; */
    /* border: 1px solid black; */
    border-collapse: collapse;
    margin-top: 20px;
    display: table-row;
}
.summary-bill-box table tr.total-price td {
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #000 !important;
}
.summary-bill-box table tr {
    border-spacing: 12px;
}
.summary-bill-box table tr td:last-child {
    text-align: end;
    color: #727272;
}
.summary-bill-box table tr.total-price td {
    padding: 20px 0;
}
.cupon-btn form.d-flx {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
}
.cupon-btn form.d-flx .form_control {
    width: auto;
    border-radius: 70px;
    padding: 15px
}
.cupon-btn form.d-flx .cta{
    padding: 15px 80px;
}
.summary-bill-deatial {
    margin-top: 20px;
}
.summary-bill-deatial p{
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #727272;
}
.summary_step .step_inner .section_head {
    width: 100%;
}


/* booking confirm step start here */

.booking-confirm {
    text-align: -webkit-center;
}
.booking-confirm-refrence-number {
    background: #65B5AF1C;
    max-width: 571px;
    anchor-name: none;
    padding: 30px;
    border: 1px solid #65B5AF;
    border-radius: 25px;
}
.booking-confirm-refrence-number h5 {
    font-size: 26px;
    font-weight: 400;
    line-height: 32.5px;
    text-align: center;
    margin-bottom: 4px;
}
.booking-confirm-refrence-number p {
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    /* text-align: left; */
    margin-bottom: 0;
}
.booking-confirm .booking-confirm-text {
    margin-bottom: 40px;
}
.booking-confirm .booking-confirm-img {
    margin-bottom: 20px;
}



/* cart page start here */

.CheckoutSection {
    position: relative;
}
.CheckoutSection .CheckoutSection-row {
    display: flex;
}
.CheckoutSection .CheckoutSection-row .CheckoutSection-col {
    flex: 0 0 calc(50% - 50px);
}
.CheckoutSection:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: #65B5AF1A;
    z-index: -1;
}
.checkout-express-head .Express-Checkout-row {
    display: flex;
    gap: 15px;
}
.checkout-express-head .Express-Checkout-row .Express-Checkout-col {
    flex: 1;
}
.checkout-express-head .Express-Checkout-row .Express-Checkout-col a {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    background: #5A2DF4;
    text-align: -webkit-center;
    padding: 15px;
    line-height: 0;
}

.CheckoutSection {
    position: relative;
}
.CheckoutSection .CheckoutSection-row {
    display: flex;
    justify-content: space-between;
}
.CheckoutSection .CheckoutSection-row .CheckoutSection-col {
    flex: 0 0 calc(50% - 50px);
}
.CheckoutSection:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: #65B5AF1A;
    z-index: -1;
}
.checkout-express-head .Express-Checkout-row {
    display: flex;
    gap: 15px;
    margin-top:15px;
}
.checkout-express-head .Express-Checkout-row .Express-Checkout-col {
    flex: 1;
}
.checkout-express-head .Express-Checkout-row .Express-Checkout-col a {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    background: #5A2DF4;
    text-align: -webkit-center;
    padding: 15px;
    line-height: 0;
}
.checkout-express-head .Express-Checkout-row .Express-Checkout-col.express-pal a {
    background: #FFC439;
}
.checkout-express-head .Express-Checkout-row .Express-Checkout-col.express-gpay a {
    background: #000000;
}
.CheckoutSection  .checkout-express-head {
    padding-bottom: 100px;
    margin-bottom: 100px;
    border-bottom: 1px solid #F0F0F0;
    position: relative;
}
.CheckoutSection .checkout-express-head:after {
    position: absolute;
    content: "or";
    left: 47%;
    bottom: -20px;
    background: #fff;
    padding: 10px 20px;
    font-weight: 600;
    line-height: 24.3px;
    color: #000;
}
.checkout-form-list h4 {
    font-family: Bremlin;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #65B5AF;
    margin-bottom: 20px;
}
.checkout-form-list:first-child h4{
    margin-bottom: 0;
}
.checkout-form-list .form_group a.checkout-login {
    text-align: end;
    display: block;
    margin-bottom: 10px;
    text-decoration: underline;
}

.checkout-form-list.login-checkout h4 {
    margin-bottom: 0;
}
.CheckoutSection .CheckoutSection-row .CheckoutSection-col form .form_row {
    flex-wrap: nowrap;
}


.billing-address-detail {
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    overflow: hidden;
}
.billing-address-detail .form-check .form-check-input {
    border-color: #65B5AF;
}
.checkout-form-list .form-check-input:checked {
    background: #65B5AF;
    accent-color: #65B5AF;
    border-color: #fff;
}
.billing-address-detail .same-billing-address {
    border-bottom: 1px solid #EBEBEB;
}
/* .billing-address-detail > div {
    padding: 25px 25px;
} */
.billing-address-detail > div .form-check {
    display: flex;
    justify-content: space-between;
}
.billing-address-detail > div .form-check label.form-check-label span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 17.55px;
    text-align: left;
    padding-left: 30px;
}
.billing-address-detail > div .form-check .shipping-method-price p {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 24.3px;
    text-align: left;
    color: #000;
}
.billing-address-detail > div .form-check label.form-check-label {
    font-size: 18px;
    font-weight: 400;
    line-height: 24.3px;
    text-align: left;
    margin-left: 10px;
}

.billing-address-detail .form-check-input:checked~label {
    background: #65B5AF33;
}
/* .billing-address-detail .form-check-input[type='radio'] {
    border: 2px solid #D9D9D9;
    accent-color: #65B5AF;
} */
.checkout-form-list .form-check-input{
    /* hiding browser el */
    appearance: none;
    /* Safari support */
    -webkit-appearance: none;
    border: 0.2rem solid #fff;
    background-color: var(--bg-color);
    border-radius: 50%;
    height: 12px;
    width: 12px;
}
.checkout-form-list .form-check-input[type='radio'] {
    outline: 1px solid #d7d7d7;
}

.checkout-form-list .form-check-input[type='radio']:hover {
    outline-color: #65B5AF;
}

.checkout-form-list .form-check-input[type='radio']:checked {
    outline-color: #65B5AF;
    background-color: #65B5AF;

}
.billing-address-detail > div .form-check label.form-check-label {
    margin: 0;
    padding: 20px 30px;
    display: block;
    width: 100%;
}
.shipping-method-check {
    width: 100%;
}
.shipping-method-check .shipping-method-price {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.billing-address-detail > div .form-check {
    position: relative;
}
.billing-address-detail .form-check .form-check-input {
    position: absolute;
    top: 22px;
    left: 5px;
}
.billing-address-detail > div .form-check label.form-check-label span {
    padding-left: 0;
}
.billing-address-detail > div:not(:last-child) .form-check-input:checked~label {
    border-radius: 8px 8px 0 0;
}
.billing-address-detail  > div .form-check-input:checked~label {
    border-radius: 0 0 8px 8px;
}
.CheckoutSection-col .checkout-form-list {
    margin-bottom: 50px;
}
.payment-type-detail .payment-type-img {
    position: absolute;
    right: 20px;
    top: 35px;
    transform: translateY(-50%);
}
.payment-type-detail .payment-check {
    width: 100%;
}
.payment-type-detail .payment-type-img {
    position: absolute;
    right: 20px;
    top: 35px;
    transform: translateY(-50%);
}
.payment-type-detail .payment-check {
    width: 100%;
}
.checkout-form-list .form-check-input[type='radio']:checked ~ .payment-detail-form {
    display: block;
}
.checkout-form-list .form-check-input ~ .payment-detail-form {
    display: none;
}

.checkout-form-list .payment-detail-form {
    background: #F5F5F5;
    padding: 25px;
}
.payment-type-detail .payment-type-img {
    position: absolute;
    right: 20px;
    top: 35px;
    transform: translateY(-50%);
}.payment-type-detail .payment-check {
    width: 100%;
}
.checkout-form-list .form-check-input[type='radio']:checked ~ .payment-detail-form {
    display: block;
}
.checkout-form-list .form-check-input ~ .payment-detail-form {
    display: none;
}
.payment-detail-form {
    background: #F5F5F5;
}
.checkout-form-list .payment-detail-form .form-group {
    margin-bottom: 20px;
    position: relative;
}
.checkout-form-list .payment-detail-form .row {
    display: flex;
    gap: 15px;
}
.checkout-form-list .payment-detail-form .form-group .tooltip-container {
    position: absolute;
    top: 15px;
    right: 10px;
}
.checkout-form-list .payment-detail-form .form-group .tooltip-container button.btn.btn-secondary {
    border: #777777;
    border-radius: 50%;
    color: #777777;
    width: 19px;
    height: 19px;
}
.checkout-form-list .payment-detail-form .form-group {
    flex: 1;
}
.billing-address-detail > div:not(:first-child) .form-check-input:checked~label {
    border-radius: 0;
}

.billing-address-detail > div:not(:first-child) .form-check-input:checked~label {
    border-radius: 0;
}
.CheckoutSection .CheckoutSection-row .CheckoutSection-col button.checkout-pay {
    width: 100%;
    background: #0F2D2F;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    color: #fff;
    padding: 17px;
    border-radius: 150px;
    box-shadow: none;
    outline: none;
    border: none;
}
.CheckoutSection .CheckoutSection-row .CheckoutSection-col button.checkout-pay:hover {
    transition: 0.3s ease-in-out;
    background: #65B5AF;
}

.CheckoutSection table.product-detail-paymant tbody td:nth-of-type(2n) {
    /* width: 100%; */
    max-width: 100%;
    padding-left: 20px;
}
.CheckoutSection table.product-detail-paymant tbody td:first-child {
    width: 100px;
}
/* .CheckoutSection table.product-detail-paymant tr:not(:last-child) {
    display: block;
    padding-bottom: 25px;
} */
.CheckoutSection table.product-detail-paymant tbody td.product-price {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
    color: #000;
}
.CheckoutSection table.product-detail-paymant tbody tr{
    width: 100%;
}
table.product-detail-paymant {
    width: 100%;
    border-collapse: collapse;
}
.CheckoutSection table.product-detail-paymant tbody tr td {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #000;
    padding: 20px 0;
}
.CheckoutSection table.product-detail-paymant tbody td:first-child img {
    width: 100%;
    border: 1px solid #65B5AF33;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
}
.CheckoutSection table.product-detail-paymant tbody tr td:last-child{
    text-align: right;
}
.CheckoutSection table.product-detail-paymant tbody tr:not(:last-child) {
    border-bottom: 1px solid #D9D9D9;
    border-collapse: collapse;
    margin-top: 20px;
    display: table-row;
    border-spacing: 12px;
}
.CheckoutSection table.product-detail-paymant tbody tr.product-pp td.product-price {
    font-size: 18px;
}
.CheckoutSection-col input[type="checkbox"] {
    accent-color: #65B5AF;
}
.CheckoutSection table.product-detail-paymant tbody tr td.product-img {
    position: relative;
}
.CheckoutSection table.product-detail-paymant tbody tr td.product-img span.product-items-number {
    position: absolute;
    top: 10px;
    right: -10px;
    width: 28px;
    height: 28px;
    background: #65B5AF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
}
.form_control.is-invalid {
    border-color: red;
  }
  
.error-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
p.error-text {
    color: red;
}  